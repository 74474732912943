
import axios from "@/plugins/axios.js"

const actions = {
  changeLayoutType({ commit },layoutType) {
    return;
    commit("CHANGE_LAYOUT", layoutType);
  },
  changeLoaderValue({ commit }, loader = false) {
    commit("TOGGLE_LOADER", loader);
  },
  changeLayoutWidth({ commit }, layoutWidth ) {
    commit("CHANGE_LAYOUT_WIDTH", layoutWidth);
  },
  changeLeftSidebarType({ commit }, leftSidebarType) {
    commit("CHANGE_LEFT_SIDEBAR_TYPE", leftSidebarType);
  },

  changeLeftSidebarSize({ commit }, leftSidebarSize) {
    commit("CHANGE_LEFT_SIDEBAR_SIZE", leftSidebarSize);
  },

  changeTopbar({ commit }, topbar) {
    commit("CHANGE_TOPBAR", topbar);
  },

  changeMenuPosition({ commit }, menuPosition) {
    commit("CHANGE_POSITION", menuPosition);
  },

  contentPagePosition({ commit }, position) {
    commit("CONTENT_PAGE_POSITION", position);
  },

  logoutUser({ commit, rootState }, action) {
    commit("LOGOUT_USER")
    return new Promise((resolve, reject) => {
      axios.post(`${rootState.domain}/api/logout`)
      .then((response) => {
          resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  universalFetch({ commit, rootState }, action) {
    return new Promise((resolve, reject) => {
      axios.get(action)
      .then((response) => {
          resolve(response)
      }).catch((error) => { reject(error) })
    })
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////
  fetchActiveUser({ commit, rootState }, action) {
    return new Promise((resolve, reject) => {
      axios.get("/account/show")
      .then((response) => {
          if(response.data.success){
            commit('UPDATE_USER_INFO', response.data.data.user)
          }
          resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  fetchCountries({ commit, rootState }, action) {
    return new Promise((resolve, reject) => {
      axios.get(`${rootState.domain}/app/countries`)
      .then((response) => {
        if(response.data.success){
          commit('SET_COUNTRIES', response.data.data.countries)
        }
        resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
}

export default actions
