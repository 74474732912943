
import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

import moduleNotification from "./modules/notification.js"
import moduleCategoryList from "./modules/category.js"
import moduleProductList from "./modules/product.js"
import moduleOrderList from './modules/order.js'
import moduleUserList from './modules/user.js'
import moduleReviewList from './modules/review.js'

Vue.use(Vuex)

export default new Vuex.Store({
    state,
    actions,
    getters,
    mutations,
    modules: {
        notification: moduleNotification,
        orderList: moduleOrderList,
        categoryList: moduleCategoryList,
        productList: moduleProductList,
        userList: moduleUserList,
        reviewList: moduleReviewList
    },
    strict: process.env.NODE_ENV !== 'production'
})
