export default [
  {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
        path: '',
        redirect: '/',
        component: () => import('@/layouts/Main.vue'),
        children: [
            {
                path: '/',
                name: 'dashboard',
                component: () => import('@views/Dashboard.vue'),
                meta: {
                  breadcrumb: [

                    {
                      text: "Dashboard",
                      active: true
                    }
                  ],
                  title: 'Welcome!',
                  authRequired: true,
                }
            },
            {
                path: '/account',
                name: 'account',
                component: () => import('@views/account/UserEdit.vue'),
                meta: {
                   breadcrumb: [
                      {
                        text: "Dashboard",
                        to:{ path:"/"}
                      },
                      {
                        text: "Account",
                        active: true
                      }
                  ],
                  title: 'Profile',
                  authRequired: true,
                },
            },
            {
                path: '/orders',
                name: 'order',
                component: () => import('@views/orders/OrderList.vue'),
                meta: {
                  breadcrumb: [
                      {
                        text: "Dashboard",
                        to:{ path:"/"}
                      },
                      {
                        text: "Orders",
                        active: true
                      }
                  ],
                  title: "Orders List",
                  authRequired: true,
                },
            },
            {
              path: '/orders/:orderId(\\d+)',
              name: 'order-view',
              component: () => import('@views/orders/OrderShow.vue'),
              meta: {
                  breadcrumb: [
                    {
                      text: "Orders",
                      to:{ path:"/orders"}
                    },
                    {
                      text: "Show",
                      active: true
                    }
                ],
                title: "View Order",
                authRequired: true,
              },
            },
            {
                path: '/categories',
                name: 'categories',
                component: () => import('@views/categories/CategoryList.vue'),
                meta: {
                breadcrumb: [
                    {
                        text: "Dashboard",
                        to: { path:"/"}
                    },
                    {
                        text: "Categories",
                        active: true
                    }
                ],
                title: "Categories List",
                authRequired: true,
                },
            },
            {
                path: '/categories/create',
                name: 'categories-create',
                component: () => import('@views/categories/CreateCategory.vue'),
                meta: {
                breadcrumb: [
                    {
                        text: "Categories",
                        to: { path:"/categories"}
                    },
                    {
                        text: "Create New",
                        active: true
                    }
                ],
                title: "Create Category",
                authRequired: true,
                },
            },
            {
                path: '/categories/:categoryId(\\d+)',
                name: 'categories-view',
                component: () => import('@views/categories/CategoryShow.vue'),
                meta: {
                breadcrumb: [
                    {
                        text: "Categories",
                        to: { path:"/categories"}
                    },
                    {
                        text: "Show",
                        active: true
                    }
                ],
                title: "View Category",
                authRequired: true,
                },
            },
            {
                path: '/products',
                name: 'products',
                component: () => import('@views/products/ProductList.vue'),
                meta: {
                breadcrumb: [
                    {
                    text: "Dashboard",
                    to: { path:"/"}
                    },
                    {
                    text: "Products",
                    active: true
                    }
                ],
                title: "Products List",
                authRequired: true,
                },
            },
            {
                path: '/products/create',
                name: 'products-create',
                component: () => import('@views/products/CreateProduct.vue'),
                meta: {
                breadcrumb: [
                    {
                        text: "Products",
                        to: { path:"/products"}
                    },
                    {
                        text: "Create New",
                        active: true
                    }
                ],
                title: "Create Product",
                authRequired: true,
                },
            },
            {
                path: '/products/:productId(\\d+)',
                name: 'products-view',
                component: () => import('@views/products/ProductShow.vue'),
                meta: {
                breadcrumb: [
                    {
                        text: "Products",
                        to: { path:"/products"}
                    },
                    {
                        text: "Show",
                        active: true
                    }
                ],
                title: "View Product",
                authRequired: true,
                },
            },
            {
                path: '/users',
                name: 'users-list',
                component: () => import('@views/users/UserList.vue'),
                meta: {
                  breadcrumb: [
                      {
                        text: "Dashboard",
                        to: { path:"/"}
                      },
                      {
                        text: "User List",
                        active: true
                      }
                  ],
                  title: "Users List",
                  authRequired: true,
                },
            },
            {
                path: '/users/create',
                name: 'user-create',
                component: () => import('@views/users/UserCreate.vue'),
                meta: {
                  breadcrumb: [
                      {
                        text: "Dashboard",
                        to: { path:"/"}
                      },
                      {
                        text: "User",
                        active: true
                      }
                  ],
                  title: "Create User",
                  authRequired: true,
                },
            },
            {
              path: '/users/:userId(\\d+)',
              name: 'user-view',
              redirect: to => {
                return { path: `/users/${to.params.userId}/edit` }
              },
              meta: {
                title: "Vew User",
                authRequired: true,
              },
            },
            {
                path: '/users/:userId(\\d+)/edit',
                name: 'user-edit',
                component: () => import('@views/users/edit/UserEdit.vue'),
                meta: {
                  breadcrumb: [
                      {
                        text: "Dashboard",
                        to: { path:"/"}
                      },
                      {
                        text: "Users",
                        to: { path:"/users"}
                      },
                      {
                        text: "Edit User",
                        active: true
                      }
                  ],
                  title: "Vew User",
                  authRequired: true,
                },
            },
            {
              path: '/users/:userId(\\d+)/chat',
              name: 'user-chat',
              component: () => import('@views/users/chat/Chats.vue'),
              meta: {
                breadcrumb: [
                    {
                      text: "Dashboard",
                      to: { path:"/"}
                    },
                    {
                      text: "Users",
                      to: { path:"/users"}
                    },
                    {
                      text: "Chat User",
                      active: true
                    }
                ],
                title: "Chat User",
                authRequired: true,

              },
            },
            {
                path: '/reviews',
                name: 'reviews',
                component: () => import('@views/reviews/ReviewList.vue'),
                meta: {
                    breadcrumb: [
                    {
                      text: "Dashboard",
                      to: { path:"/"}
                    },
                    {
                      text: "Review List",
                      active: true
                    }
                  ],
                  title: 'Reviews',
                  authRequired: true,
                }
            },
            {
                path: '/payments',
                name: 'payments',
                component: () => import('@views/payments/Payments.vue'),
                meta: {
                    breadcrumb: [
                      {
                        text: "Dashboard",
                        to: { path:"/"}
                      },
                      {
                        text: "Payments List",
                        active: true
                      }
                  ],
                  title: "Payments",
                  authRequired: true,
                }
            },
            {
                path: '/notifications',
                name: 'notifications',
                component: () => import('@views/notification/Notifications.vue'),
                meta: {
                    breadcrumb: [
                      {
                        text: "Dashboard",
                        to:{ path:"/"}
                      },
                      {
                        text: "Notifications List",
                        active: true
                      }
                  ],
                  title: "Notifications",
                  authRequired: true,
                }
            },
            {
                path: '/transactions',
                name: 'transactions',
                component: () => import('@views/transaction/TransactionList.vue'),
                meta: {
                    breadcrumb: [
                      {
                        text: "Dashboard",
                        to:{ path:"/"}
                      },
                      {
                        text: "Transactions List",
                        active: true
                      }
                  ],
                  title: "Transactions",
                  authRequired: true,
                }
            },
            {
                path: '/settings/coupons',
                name: 'coupons',
                component: () => import('@views/settings/coupons/Coupons.vue'),
                meta: {
                    breadcrumb: [
                      {
                        text: "Dashboard",
                        to:{ path:"/"}
                      },
                      {
                        text: "Coupon List",
                        active: true
                      }
                  ],
                  title: "Coupons",
                  authRequired: true,
                }
            },
        ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
        path: '',
        component: () => import('@/layouts/FullPage.vue'),
        children: [
            {
                path: '/login',
                name: 'login',
                component: () => import('@/views/pages/Login.vue'),
                meta: {
                  title: "Login",
                  guestRequired: true,
                }
            },
            {
                path: '/error-404',
                name: 'error-404',
                component: () => import('@/views/pages/Error404.vue'),
                meta: {
                  title: "404 - Error"
                }
            },
            {
                path: '/error-500',
                name: 'error-500',
                component: () => import('@/views/pages/Error500.vue'),
                meta: {
                  title: "500 - Error"
                }
            },
        ]
    },
    // Redirect to 404 page, if no match found
    {
        path: '*',
        redirect: '/error-404'
    }
]