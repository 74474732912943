
let colors = {
  // primary : '#7367F0',
	primary : '#1e1e1e',
	success : '#28C76F',
	danger  : '#EA5455',
	warning : '#FF9F43',
	dark    : '#1E1E1E',
}

// CONFIGS
const themeConfig = {
  layoutType: "vertical",
  layoutWidth: "fluid",
  leftSidebarType: "gradient",
  leftSidebarSize: "default",
  menuPosition: "fixed",
  topbar: "light",
  contentPageFixed: false,
  loader: false,
}

import Vue from 'vue'
Vue.use({ theme:{ colors } })

export default themeConfig
